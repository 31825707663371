@import "general.scss";
@import url('https://fonts.googleapis.com/css2?family=Passion+One&display=swap');

table.table { 
	border-collapse: collapse; 
}

table.table td { 
  background-color: transparent;
	padding: .1rem; 
	border: 1px solid white !important; 
  color: white;
	text-align: center; 
  font-size: 70%;
}

.sampleText {
  line-height:1.5em;
  min-height:6em;
  border: 1px solid white;
}

.typingInput {
  font-size: 150%;
  border: 0 !important;
  outline: 0 !important;
  outline: none !important;
  border-radius: 3px;
  padding: .2em .5em .2em .5em;
}

.whiteInput {
  font-size: 150%;
  border: 0 !important;
  outline: 0 !important;
  outline: none !important;
  border-radius: 3px;
  padding: .2em .5em .2em .5em;
  background-color: white;
  color: black;
}

::placeholder {
  color: rgba(28, 210, 220, 0.363);
}

:-ms-input-placeholder, ::-ms-input-placeholder {

}

.dropdownButton {
  min-width: 10em;
}

.smallText {
  font-size: 70%;
}

.smallerText {
  font-size: 40%;
}

.dropdownButtonWide {
  @extend .dropdownButton;
  min-width: 15em;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownContent {
  position: absolute;
  background-color: #f1f1f1;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 10001;
  display: none;
  flex-direction: column;
  align-items: stretch;
  min-width: 10em;
  border: 1px solid #dcdcdc;
  max-height: 20em;
  overflow-y: auto;
  color: black;
}

.dropdownContent.smallerText {
  min-width: 1em;
}

.dropdownItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  height: 2em;
  padding: 0 1em;
  cursor: pointer;
}

.dropdownShow {
  display:inline-flex;
}

.imageBottom {
  bottom: 0;
  width: 100%;
  height: auto;
}

body {
  background-image: url("/image/typeBuddyBottom.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom;
  background-size: 100%;
}

.car {
  transform: scaleX(-1);
  width: 128px;
  height: 64px;
  position: fixed;
  left: -1000px;
  bottom: -1000px;
  background-size: cover;
}

.car0 { background-image: url("/image/car-yellow-van.png"); }
.car1{ background-image: url("/image/car-yellow-small.png"); }
.car2 { background-image: url("/image/car-yellow-sedan.png"); }
.car3 { background-image: url("/image/car-white-suv.png"); }
.car4 { background-image: url("/image/car-white-sedan.png"); }
.car5 { background-image: url("/image/car-red-van.png"); }
.car6 { background-image: url("/image/car-red-sedan.png"); }
.car7 { background-image: url("/image/car-pink-small.png"); }
.car8 { background-image: url("/image/car-lightblue-sedan.png"); }
.car9 { background-image: url("/image/car-green-small.png"); }
.car10 { background-image: url("/image/car-green-sedan.png"); }
.car11 { background-image: url("/image/car-blue-suv.png"); }
.car12 { background-image: url("/image/car-blue-small.png"); }
.car13 { background-image: url("/image/car-black-sedan.png"); }

.carTag {
  font-size: 40%;
  background-color: #2f2f2f;
  padding: .1em .2em .1em .2em;
  border-radius: 2px;
  color: white;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  position: fixed;
  display: inline-block;
}

.flag {
  position: fixed;
  bottom: calc(66px + 9vw);
  right: 4vw;
}

.background-canvas {
  position: fixed;
  left:0;
  top:0;
  bottom:0;
  right:0;
  background:transparent;
}

.word {
  padding-left: .08em;
  padding-right: .08em;
  border-radius: 3px;
}

.currentword {
  background-color: #15a0a5;
  color: white;
}

.word-correct {
  color: #9ef79e !important;
}

.word-incorrect {
  color: #fca6a6 !important;
}

.joinCode {
  text-shadow: 1px 1px 4px gray;
}

.logoText {
  font-family: Passion One, sans-serif;
}

ul.share-buttons{
  list-style: none;
  padding: 0;
}

ul.share-buttons li{
  display: inline;
}

ul.share-buttons .sr-only{
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.chatLink {
  margin: 1em;
  color: white;
  font-size: 50%;
}

.linkWhite {
  color: white;
  font-weight: bolder;
}

a:hover {
  color: white;
  font-weight: bolder;
  text-decoration: underline;
}

.advertText {
  color: white;
  text-shadow: 1px 1px 4px gray;
}
